<template>
  <div id="demoModels">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">
            Demo Models <br />
            Pogarskiy Design
          </h1>
          <h2 class="sectionDescription">
            Обучающие и <br />
            демонстрационные 3D модели
          </h2>
          <router-link :to="{ name: 'viewer3Dconstructor' }" class="btnCreateDemoModel"
            >Создать демо модель</router-link
          >
        </div>
      </div>
    </div>
    <section id="demoModels_view">
      <div class="wrapper">
        <p class="textBold">
          Демо модели были разработаны для демонстрации на экране видов реставраций, дефектов и препаровки зубов, а
          также для скачивания и создания обучающих и ознакомительных шаблонов методом 3D печати. 3D модели позволяют
          изготавливать демонстрационные работы, модели для обучения, помогают тестировать оборудование и материалы на
          предмет точности. Данную разработку можно использовать в стоматологических кабинетах, зуботехнических
          лабораториях, семинарах, мастер-классах, выставках, образовательных учреждениях и стоматологических магазинах.
          Выбранную сборку модели можно скачать в STL формате и редактировать в большом спектре 3D программ. Конструктор
          3D моделей является информационным, высокотехнологичным и многофункционалным ресурсом, позволяющим
          удовлетворить требованиям современной стоматологии.
        </p>
        <div class="demoModelsPreview">
          <img src="./../../assets/images/demoModels/preview_1.png" alt="" />
          <img src="./../../assets/images/demoModels/preview_2.png" alt="" />
          <img src="./../../assets/images/demoModels/preview_3.png" alt="" />
          <img src="./../../assets/images/demoModels/preview_4.png" alt="" />
          <img src="./../../assets/images/demoModels/preview_5.png" alt="" />
          <img src="./../../assets/images/demoModels/preview_6.png" alt="" />
          <img src="./../../assets/images/demoModels/preview_7.png" alt="" />
          <img src="./../../assets/images/demoModels/preview_8.png" alt="" />
        </div>
        <p class="textBold">
          3D демо модели предоставлены в виде набора из 198 STL (бинарный STL) файлов. Файлы разбиты на группы: модели
          (верхняя и нижняя), столбики с зубами в полной анатомии, столбики с имитацией обработаных зубов под
          полно-анатомические коронки, столбики с имитацией обработки зубов под виниры (фронтальная группа верхней и
          нижней челюстей) и вкладки (жевательные зубы верхней и нижней челюстей), столбики имитирующие отсутствующие
          зубы, вкладки и виниры (соответствующие зубам с имитацией обработки), коронки (соответствующие зубам с
          имитацией обработки), зубы в полной анатомии (для промежутков мостовидных протезов). Все столбики,
          соответствующие модели, выполнены с учетом зазора 0.02 мм, что обеспечивает плотную посадку в лунку модели. На
          каждом из столбиков предусмотрены пазы, препятствующие ротации. В нижней части предусмотрены отверстия для
          удобного извлечения компонентов модели. Все элементы верхней и нижней челюсти выполнены с учетом окклюзии. Все
          файлы являются уникальными и авторскими. Библиотека была разработана зубным техником Погарским Антоном.
        </p>
      </div>
    </section>
    <section id="demoModels_types">
      <div class="wrapper">
        <h3 class="pageSectionTitle">Типы элементов моделей</h3>
        <div class="modelFlex-container">
          <div class="container">
            <img class="containerImg" src="./../../assets/images/demoModels/model_type_1.png" alt="" />
            <span class="model_title">Полная анатомия</span>
          </div>
          <div class="container">
            <img class="containerImg" src="./../../assets/images/demoModels/model_type_2.png" alt="" />
            <span class="model_title">Редуцированные зубы</span>
          </div>
          <div class="container">
            <img class="containerImg" src="./../../assets/images/demoModels/model_type_3.png" alt="" />
            <span class="model_title">Коронки</span>
          </div>
          <div class="container">
            <img class="containerImg" src="./../../assets/images/demoModels/model_type_4.png" alt="" />
            <span class="model_title">Удаленные зубы</span>
          </div>
          <div class="container">
            <img class="containerImg" src="./../../assets/images/demoModels/model_type_5.png" alt="" />
            <span class="model_title">Зубы, замещающие отсутствующие</span>
          </div>
          <div class="container">
            <img class="containerImg" src="./../../assets/images/demoModels/model_type_6.png" alt="" />
            <span class="model_title">Частичная редукция зубов под вкладки и виниры</span>
          </div>
          <div class="container">
            <img class="containerImg" src="./../../assets/images/demoModels/model_type_7.png" alt="" />
            <span class="model_title">Владки и виниры</span>
          </div>
        </div>
      </div>
    </section>
    <section id="demoModels_manual">
      <div class="wrapper">
        <h3 class="pageSectionTitle">Последовательность создания демо моделей (4 шага)</h3>
        <ul class="modelsCounter">
          <li>Выберите модель, используя формулу или кнопки для выбора в левом меню</li>
          <li>
            Укажите состояние зубов, использую формулу с зубами или выпадающий список с перечнем зубов (редуцированные,
            частичная редукция, отсутствующие зубы)
          </li>
          <li>
            Укажите наличие коронок, вкладок или зубов на месте препарирванных или отсутствующих (используйте правое
            меню для регулировки прозрачностей элементов)
          </li>
          <li>
            Воспользуйтесь кнопкой "Скачать модель STL" для скачивания всех компонентов моделей (скачивая модели, Вы
            получаете их в наилучшем качестве, готовые к печати)
          </li>
        </ul>
      </div>
    </section>
    <section id="demoModels_controls">
      <div class="wrapper">
        <h3 class="pageSectionTitle">Схема управления мышью</h3>
        <img src="./../../assets/images/demoModels/Mouse_control_scheme.png" class="controls_img" alt="" />
        <div class="controls_video">
          <iframe src="https://www.youtube.com/embed/IXgE6EnHhgI" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </section>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";

export default {
  name: "DemoModels",
  components: { mainMenu, mainFooter },
  data() {
    return {};
  },
};
</script>

<style lang="sass">
#demoModels
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .headerPreview
		display: flex
		min-height: 360px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/demoModelsBackground.png')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			@media screen and (max-width: 425px)
				display: flex
				flex-direction: column
				align-items: center
				text-align: center
			& .sectionTitle
				font-size: 2rem
				color: #fff
				padding-bottom: 1.5rem
				margin: 0 0 1.5rem
				border-bottom: 1px solid #ffbbf1
				@media screen and (max-width: 425px)
					font-size: 1.2rem
			& .sectionDescription
				font-family: 'Ropa Sans Pro SC Regular'
				font-weight: 400
				font-size: 1.5rem
				color: #ffbbf1
				margin: 0 0 1.5rem
				@media screen and (max-width: 425px)
					font-size: 1.2rem
			& .btnCreateDemoModel
				display: block
				background: #6fb515
				font-weight: bold
				text-align: center
				color: #fff
				padding: 0.5rem 2rem
				border: 1px solid transparent
				cursor: pointer

#demoModels_view
	padding: 2rem 1rem
	& .demoModelsPreview
		display: flex
		justify-content: center
		flex-wrap: wrap
		align-items: center
		padding: 2rem 0
		& img
			display: inline-block
			width: calc(100% / 4)
			@media screen and (max-width: 768px)
				width: calc(100% / 3)
			@media screen and (max-width: 425px)
				width: calc(100% / 2)

#demoModels_types
	padding: 2rem 0
	background-color: #d9d8d8
	& .modelFlex-container
		display: flex
		flex-wrap: wrap
		justify-content: center
		& .container
			display: block
			margin: 1rem
			width: 100%
			min-width: 210px
			max-width: 220px
			& .containerImg
				width: 100%
			& .model_title
				display: block
				width: 100%
				font-size: 1rem
				font-weight: bold
				text-align: center

#demoModels_manual
	padding: 2rem 1rem
	& ul.modelsCounter
		margin: 0
		padding: 0
		list-style: none
		& li
			counter-increment: manual-step
			display: flex
			align-items: center
			margin: 0.5rem 0
			&::before
				content: counter(manual-step)
				display: flex
				align-items: center
				justify-content: center
				width: 62px
				height: 62px
				padding: 30px
				margin-right: 2rem
				border: 1px solid transparent
				border-radius: 50%
				color: #fff
				background: #959595
				font-size: 2.3rem
				font-weight: bold
				@media screen and (max-width: 768px)
					font-size: 1.5rem
					width: 42px
					height: 42px
					padding: 20px
					margin-right: 1rem

#demoModels_controls
	padding: 2rem 1rem
	& .controls_img
		display: block
		width: 100%
		max-width: 770px
		height: auto
		margin: 0 auto 2rem
	& .controls_video
		position: relative
		padding-bottom: 65.25%
		padding-top: 30px
		height: 0
		overflow: auto
		-webkit-overflow-scrolling: touch
		border: solid black transparent
		& iframe
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
</style>
